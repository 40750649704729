import {connect} from "react-redux";
import {Dispatch} from "redux";
import {App, DispatchProps, StateProps, InnerProps} from "../components/App";
import {RootState} from "../types";
import {RootAction} from "../actions";

function mapStateToProps(_rs: RootState): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch<RootAction>): DispatchProps {
    return {};
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(App);