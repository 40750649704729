import React, {useEffect, useState} from "react";
import {
    IMAGES,
    KButton,
    KBUTTON_SIZE,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KNavbar,
    KNavBrandFixed,
    KNavDropdown,
    KNavDropdownItem,
    KNavLangSelector,
    KNavLink
} from "@kopjra/uikit";
import {translations} from "../locale";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {useAuth0} from "@auth0/auth0-react";
import {Routes} from "../utils/router";
import {computeCartTotal, computeTotal, decomp, isFixedPrice, isPrice} from "../utils/commons";
import {SolutionKey, SOLUTIONS} from "../types/solutions";
import {CartType, ConsumptionPrice, LevelPrice, PackagePrice, PRICE_TYPE} from "../types/products";
import {IVA} from "../utils/constants";
import {useLocation} from "react-router-dom";

export interface StateProps {
    locale: string;
    cart: CartType;
}

export interface DispatchProps {
    onChangeLocale: (locale: string) => void;
    onGoHome: () => void;
    onGoAccount: () => void;
    onGoToCart: () => void;
    onRemoveFromCart: (id: string) => void;
    onEditCartElement: (solution: SolutionKey, id: string) => void;
    onGoBilling: () => void;
    onGoManagePayment: () => void;
    onGoSubscriptions: () => void;
    onGoInvoices: () => void;
    onGoSuperUse: () => void;
    onGoSuperAdmin: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Toolbar: React.FC<Props> = ({onGoSuperAdmin, onGoSuperUse, onGoInvoices, onGoSubscriptions, onGoManagePayment, locale, onGoBilling, onChangeLocale, onGoHome, onGoAccount, cart, onGoToCart, onRemoveFromCart, onEditCartElement}) => {
    const {isAuthenticated, loginWithRedirect, logout, getIdTokenClaims} = useAuth0();
    const location = useLocation();
    const [claims, setClaims] = useState<string[] >([]);

    useEffect(() => {
        if (isAuthenticated) {
            getIdTokenClaims().then((c) => {
                setClaims((c && c["assigned_roles"]) || []);
            });
        }
    }, [getIdTokenClaims, isAuthenticated]);

    const [cartTotal] = computeCartTotal(cart);
    return (
        <KNavbar
            brand={
                <KNavBrandFixed iconPartial={"kopjra"} appName={"Store"}/>
            }
            rightMost={
                <>
                    <KNavLangSelector onChangeLocale={(loc) => onChangeLocale(loc)} locale={locale} translations={translations} alignRight={true}/>
                    {(Object.keys(cart).length  > 0 && false) ? (
                        <div className="toolbar-cart">
                            <KNavDropdown
                                id={"cart"}
                                title={<KButton id={"toolbar-shopping-cart"} text={<i className="fal fa-shopping-cart" />} onClick={() => {}} variant={KBUTTON_VARIANT.stript} size={KBUTTON_SIZE.nm} pill={`${Object.keys(cart).length}`} />}
                                noCaret={true}
                                alignRight={true}
                            >
                                {Object.keys(cart).map((id, index) => {
                                    const decomped = decomp(id);
                                    const productId = decomped.id;
                                    const solutionKey = decomped.solution;
                                    const prices = cart[id];
                                    const notFixed = prices.find(isPrice);
                                    const fixed = prices.filter(isFixedPrice);
                                    let priceTranslation: JSX.Element | undefined;
                                    if (notFixed) {
                                        switch (notFixed.type) {
                                            case PRICE_TYPE.level:
                                                priceTranslation = <Translate value={`${productId}.${notFixed.type}.shorts.item` as any} count={I18n.l((notFixed as LevelPrice).from)}/>;
                                                break;
                                            case PRICE_TYPE.package:
                                                priceTranslation = <Translate value={`${productId}.${notFixed.type}.shorts.item` as any} count={I18n.l((notFixed as PackagePrice).to)}/>;
                                                break;
                                            case PRICE_TYPE.consumption:
                                                priceTranslation = <Translate value={`${productId}.${notFixed.type}.shorts.item` as any} init={I18n.l((notFixed as ConsumptionPrice).price)}/>;
                                                break;
                                        }
                                    }
                                    return <div className="product" key={index}>
                                        <img src={IMAGES[productId].still.large.dark} alt={`${productId}_icon`} width={50}/>
                                        <div className="central">
                                            <span className="title"><Translate value={`${productId}.title`}/></span>
                                            <div>
                                                <span className="details">
                                                    {priceTranslation}
                                                </span>
                                                {fixed.map((f, index) => (
                                                    <span className="details" key={index}>+ <Translate value={`${productId}.addons.shorts.addons.${f.name}` as any}/></span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="pull-right text-right">
                                            <span className="title">
                                                <Localize
                                                    value={computeTotal(prices)[0]}
                                                    options={{style: "currency", currency: "EUR"}}
                                                />
                                            </span>
                                            <KButton id={"toolbar-edit"} text={<i className="fal fa-edit"/>} variant={KBUTTON_VARIANT.stript}
                                                     size={KBUTTON_SIZE.xs} onClick={() => onEditCartElement(solutionKey, id)}
                                            />
                                            &nbsp;
                                            &nbsp;
                                            <KButton id={"toolbar-delete"} text={<i className="fal fa-trash"/>} variant={KBUTTON_VARIANT.stript}
                                                     size={KBUTTON_SIZE.xs} onClick={() => onRemoveFromCart(id)}
                                            />
                                        </div>
                                    </div>
                                })}
                                <div className="product last">
                                    <div className="pull-left titlish">
                                        <Translate value="productDetail.total" className="bold"/> <Translate value="productDetail.iva"/>
                                    </div>
                                    <div className="pull-right titlish">
                                        <Localize
                                            value={cartTotal + cartTotal * IVA}
                                            options={{style: "currency", currency: "EUR"}}
                                            className="bold"
                                        />
                                    </div>
                                    <div className="cartButton">
                                        <KButton id={"toolbar-goto-cart"} text={<Translate value="cart.title"/>} fill={true}
                                                 type={KBUTTON_TYPE.button} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.sm}
                                                 onClick={() => onGoToCart()}
                                        />
                                    </div>
                                </div>
                            </KNavDropdown>
                        </div>
                    ) :  null}
                </>
            }
        >
            <KNavLink active={location.pathname === Routes.HOME} onClick={() => onGoHome()} element={<Translate value="home.title"/>}/>
            <KNavDropdown id={"solutions.dropdown"} title={<Translate value={"generic.solutions"}/>}>
                {(Object.keys(SOLUTIONS) as SolutionKey[]).map((s, index) => (
                    <KNavDropdownItem key={index} onClick={() => { window.open(SOLUTIONS[s]?.link, "_blank") }}>
                        <Translate value={"generic.access"}/><Translate value={`${s}.title`}/>
                    </KNavDropdownItem>
                ))}
            </KNavDropdown>
            {claims.includes("st:wf:manual") ? <KNavLink active={location.pathname === Routes.SUPER_USE} onClick={() => onGoSuperUse()} element={<Translate value="admin.title"/>}/> : null}
            {(claims.includes("st:admin") || claims.includes("god")) ? <KNavLink active={location.pathname === Routes.SUPER_ADMIN} onClick={() => onGoSuperAdmin()} element={<Translate value="superAdmin.title"/>}/> : null}
            <KNavDropdown id={"user.profile-dropdown"} title={<Translate value="user.account"/>}>
                {!isAuthenticated ? (
                    <>
                        <div style={{padding: 20, width: "100%"}}>
                            <KButton id={"toolbar-login"} text={<span><i className="fal fag fa-sign-in"/> <Translate value="user.login"/></span>} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.sm}
                                     onClick={() => loginWithRedirect({ui_locales: locale})} fill={true}/>
                        </div>
                        <KNavDropdownItem onClick={() => {window.open(`mailto:sales@kopjra.com`, "_blank")}}>
                            <Translate value="user.support"/>
                        </KNavDropdownItem>
                     </>
                ) : (
                    <>
                        <KNavDropdownItem onClick={() => onGoAccount()}>
                            <Translate value="user.profile"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => onGoBilling()}>
                            <Translate value="user.billing"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => onGoSubscriptions()}>
                            <Translate value="user.subscriptions"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => onGoInvoices()}>
                            <Translate value="user.invoices"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => onGoManagePayment()}>
                            <Translate value="payment.paymentMethod"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => {window.open(`mailto:sales@kopjra.com`, "_blank")}}>
                            <Translate value="user.support"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => logout({returnTo: window.location.origin})}>
                            <Translate value="user.logout"/>
                        </KNavDropdownItem>
                    </>
                )}
            </KNavDropdown>
        </KNavbar>
    );
}
