import React, {useEffect} from "react";
import {IMAGES, KButton, KBUTTON_VARIANT, KCard, KContainer, KSpace, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {Alert, Col, Row} from "react-bootstrap";
import {SolutionKey, SOLUTIONS} from "../types/solutions";
import {navigate, Routes} from "../utils/router";
import {priceIdToString, priceTotal, Product, productIdOrdering} from "../types/products";
import {ENV_Coupons, ENVIRONMENT} from "../utils/constants";

export interface StateProps {
    locale: string;
    products?: Product[];
}

export interface DispatchProps {
    onGetProducts: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Home: React.FC<Props> = ({products, onGetProducts}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        onGetProducts();
    }, [onGetProducts]);

    const solutions = products ? Array.from(new Set(products.map((p) => p.solution))) : undefined;
    function getFreePlanLink(solution: SolutionKey): string | null {
        if (products) {
            const solutionProducts = products.filter((p) => p.solution === solution);
            if (solutionProducts.length === 1) {
                solutionProducts[0].prices.sort((a, b) => priceTotal(a) - priceTotal(b));
                const zeroPrices = solutionProducts[0].prices.filter((p) => priceTotal(p) === 0);

                if (zeroPrices.length === 0) {
                    const productId = solutionProducts[0].id;
                    if (ENV_Coupons[ENVIRONMENT][productId]) {
                        return `${Routes.CART}?prices=${priceIdToString(solutionProducts[0].prices[0])}&codes=${ENV_Coupons[ENVIRONMENT][productId]}`;
                    } else {
                        return null;
                    }
                } else if (zeroPrices.length === 1) {
                    return `${Routes.CART}?prices=${priceIdToString(zeroPrices[0])}`;
                } else {
                    return Routes.SOLUTION_DETAIL(solution);
                }
            } else {
                return Routes.SOLUTION_DETAIL(solution);
            }
        } else {
            return Routes.SOLUTION_DETAIL(solution);
        }
    }

    const alertText = I18n.t("globalAlert", {dangerousHTML: true});

    return (
        <KContainer>
            <h5><img src={IMAGES.kopjra.still.large.default} height={85} alt="kopjra logo"/></h5>
            <h3><Translate value="home.title"/></h3>
            <h4><Translate value="home.subtitle" dangerousHTML={true}/></h4>
            {alertText && alertText !== "globalAlert" && <Alert variant="warning" style={{textAlign: "center"}}><Translate value={"globalAlert"} dangerousHTML={true}/></Alert>}
            <KSpace spaces={3}/>
            {solutions ? (
                <Row>
                    {solutions.sort((a, b) => productIdOrdering[a] < productIdOrdering[b] ? -1 : 1).map((s, index) => {
                        const freePlanLink = getFreePlanLink(s);
                        return (
                            <Col lg={4} key={index}>
                                <KCard fillHeight={true}>
                                    <h5><img src={IMAGES[s].still.large.default} height={70} alt={s}/></h5>
                                    <KSpace spaces={0.5}/>
                                    <h5><Translate value={`${s}.title`}/></h5>
                                    <h6><Translate value={`${s}.subtitle`}/></h6>
                                    <KSpace spaces={1.5}/>
                                    <p><Translate value={`${s}.description`}/></p>
                                    <div className="mt-auto">
                                        <div className="from"><Translate value="generic.from"/></div>
                                        <div className="big-price">{SOLUTIONS[s]?.basePrice} €{SOLUTIONS[s]?.modality ? <div className="sub">/ <Translate  value={`generic.modality.${SOLUTIONS[s]?.modality}` as any}/></div> : null}</div>
                                        {SOLUTIONS[s]?.subtext ? <div className="from"><Translate value={SOLUTIONS[s]?.subtext || "" as any}/></div> : null}
                                        <KSpace spaces={1.5}/>
                                        <KButton id={"home-conf-buy"} text={<Translate value="generic.confBuy"/>} onClick={() => navigate(Routes.SOLUTION_DETAIL(s))} fill={true}/>
                                        {freePlanLink && <KSpace spaces={1.5}/>}
                                        {freePlanLink &&  <KButton id={"home-free-plan"} text={<Translate value="generic.freePlan"/>} onClick={() => navigate(freePlanLink)} fill={true} variant={KBUTTON_VARIANT["outline-success"]}/>}
                                        <KSpace spaces={1.5}/>
                                        <h6 style={{marginBottom: 0}}><a className="link" href={SOLUTIONS[s]?.link} target="_blank" rel="noopener noreferrer"><Translate value={"generic.access"}/><Translate value={`${s}.title`}/></a></h6>
                                        {!freePlanLink && <KSpace spaces={5}/>}
                                    </div>
                                </KCard>
                            </Col>
                        )
                    })}
                </Row>
            ) : (
                <h1><KSpinner size={KSpinnerSize.nm}/></h1>
            )}
            <KSpace spaces={3}/>
            <h4><a className="link" href={"https://estimator.kopjra.com/"} target="_blank" rel="noopener noreferrer"><Translate value="home.contactus"/></a></h4>
            <KSpace spaces={2}/>
        </KContainer>
    );
}
