import {PLAN_SUBSCRIPTION_STATUS} from "../types/customer";
import {ENV} from "../utils/constants";

const en = {
    foreignProvince: "Foreign",
    locale: {
        en: "EN",
        it: "IT",
    },
    user: {
        profile: "User Profile",
        account: "Account",
        logout: "Logout",
        login: "Login",
        support: "Support",
        name: "Name",
        email: "Email",
        verified: "Email verified",
        modify: "Modify",
        save: "Save changes",
        resetPasswordMessage: "An email was sent to your email address with instructions to change your password",
        resendEmail: "Resend",
        resendEmailMessage: "An email was sent to your email address with instructions to verify your account. After verifying it, reload this page",
        billing: "Billing Information",
        billingSaved: "Data saved successfully",
        subscriptions: "Subscriptions",
        subscriptionsEmpty: "In this page you may find your subscriptions. At this moment you don't have any. <br/> <br/> <br/> <a href=\"/\" class=\"link\">Return to the Store's home page</a>",
        subsTable: {
            "id": "Id",
            "product": "Solution",
            "total": "Net price",
            "status": "Status",
            "created": "Creation date",
            "startDate": "Start",
            "cancelAt": "Expiration date",
            "canceledAt": "ended",
            "downloadInvoice": "Download Invoice",
            "cancel": "Cancel",
            "access": "Access",
            autoRenewal: "Automatic renewal",
            canceledTooltip: "The subscription has been canceled. You won't be able to use the service wont from the specified date.",
            renewTooltip: "Subscription automatic renewal date."
        },
        status: {
            [PLAN_SUBSCRIPTION_STATUS.ACTIVE]: "Active",
            [PLAN_SUBSCRIPTION_STATUS.CANCELED]: "Canceled",
            [PLAN_SUBSCRIPTION_STATUS.INCOMPLETE]: "Incomplete",
            [PLAN_SUBSCRIPTION_STATUS.UNPAID]: "Unpaid",
            [PLAN_SUBSCRIPTION_STATUS.PAST_DUE]: "Past due",
            [PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: "Incomplete expired",
            [PLAN_SUBSCRIPTION_STATUS.TRIALING]: "Trialing",
        },
        invoices: "Invoices",
        invoicesEmpty: "In this page you may find all your invoices. At this moment you don't have any. <br/> <br/> <br/> <a href=\"/\" class=\"link\">Return to the Store's home page</a>",
        invoiceTable: {
            id: "Id",
            solution: "Solution",
            type: "Type",
            net: "Net price",
            date: "Date"
        },
        invoiceType: {
            FAT: "Invoice",
            NDC: "Credit note",
        },
    },
    home: {
        title: "Store",
        subtitle: "Welcome to <a href='https://www.kopjra.com' target='_blank' rel='noopener noreferrer' class='link'>Kopjra</a>'s digital store",
        contactus: "Need a tailored quotation?",
        request: "Request a quotaion",
        contactSubject: "Tailored solution: [SOLUTION]",
        contactBody: "Hello,\nWe would like a tailored solution for [SOLUTION],\n",
    },
    admin: {
        title: "Administration",
        cardTitle: "Web Forensics",
        description: "Assign a subscription to a user. If the user is new it will be automatically created and a password will be automatically generated and sent via email.",
        addUpdate: "Add/Update",
        email: "Email",
        firstName: "First name",
        lastName: "Last name",
        package: "Package",
        created: `<div class="text-center">
        <i class="fal fa-circle-check text-success fa-3x"></i><br/> User created successfully<br/>
        An automatically generated password has been sent to the user's email address<br/>
        And the selected subscription has been assigned to the user<br/>
        </div>
        `,
        updated: `<div class="text-center">
        <i class="fal fa-circle-info text-primary fa-3x"></i><br/><b>User updated successfully</b><br/>      
        The selected subscription has been assigned to the user<br/>
        </div>
        `,
        error: `<div class="text-center">
        <i class="fal fa-circle-exclamation text-danger fa-3x"></i><br/><b>Error creating/updating user</b><br/>      
        %{errorMessage}<br/>
        </div>
        `,
    },
    superAdmin: {
        title: "GOD MODE",
        cardTitle: "Administration tools",
        cardConsumptionTitle: "User Consumption",
        exportCustomerCSV: "Export all customers from Stripe",
        email: "Email",
        partner: "Partner",
        activeSolutions: "Active solutions",
        activeProducts: "Active products",
        details: "Details",
        currentConsumed: "Current consumed",
    },
    "web-intelligence": {
        title: "Web Intelligence",
        subtext: "for the base package",
        subtitle: "Management of Internet investigations",
        url: "https://app.webintelligence.kopjra.com",
        recurrence: "Recurrence",
        planLabel: "Package",
        description: "Solution suite to manage deep investigations on the Internet. Particularly, the detection and fast removal of intellectual property, reputation and industrial infringements on clear, deep and dark web.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "Product with an active subscription. The active configuration can not be modified, please wait the natural end of the subscription or contact support.",
        level: {
            item: "For <b>%{count}</b> Web Intelligence credits pay a fixed amount of: <b>%{fixed}/%{duration}</b> and over-quota at: <b>%{price}/each</b> - valid for <b>1 %{duration}</b>",
            item_1: "For <b>%{count}</b> Web Intelligence credit pay a fixed amount of: <b>%{fixed}/%{duration}</b> and over-quota at: <b>%{price}/each</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "For %{count} credits / %{duration}",
                item_1: "For %{count} credit / %{duration}",
            },
            durationDetail: "The credits will last one <b>%{duration}</b> from the purchase, and can be used in all products within Web Intelligence.",
        },
        package: {
            item: "<b>%{count}</b> Web Intelligence credits for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> Web Intelligence credit for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} credits / %{duration}",
                item_1: "%{count} credit / %{duration}",
            },
            durationDetail: "The credits will last one <b>%{duration}</b> from the purchase, and can be used in all products within Web Intelligence.",
            plan1: {
                month: "Pack of <b>%{count}</b> searches at <b>%{price}/%{duration}</b>",
                month_1: "One search at <b>%{price}/%{duration}</b>",
                year: "Pack of <b>%{count}</b> searches at <b>%{price}/%{duration}</b>",
                year_1: "One search at <b>%{price}/%{duration}</b>",
                conditions: "The searches will be renewed every <b>%{duration}</b>. You will also have all the tools to perform the result analysis and validation."
            },
            plan2: {
                month: "<b>%{count}</b> searches, <b>3</b> takedown wells and <b>1000</b> N&TDs at <b>%{price}/%{duration}</b>",
                month_1: "one search, <b>3</b> takedown wells and <b>1000</b> N&TDs at <b>%{price}/%{duration}</b>",
                year: "<b>%{count}</b> searches, <b>3</b> takedown wells and <b>12000</b> N&TDs at <b>%{price}/%{duration}</b>",
                year_1: "one search, <b>3</b> takedown wells and <b>12000</b> N&TDs at <b>%{price}/%{duration}</b>",
                conditions: "The searches will be renewed every <b>%{duration}</b>. You will also have all the tools to perform the result analysis and validation, besides access to the Takedown platform."
            },
            freeTrial: {
                month: "Free <b>%{count}</b> searches for a <b>%{duration}</b>",
                month_1: "Free search for a <b>%{duration}</b>",
                year: "Free <b>%{count}</b> searches for a <b>%{duration}</b>",
                year_1: "Free search for a <b>%{duration}</b>",
                conditions: "Free trial period that gives access to detection and analysis, renewed each <b>%{duration}</b>."
            },
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each credit</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the credits consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
    },
    "web-forensics": {
        title: "Web Forensics",
        subtitle: "Forensic acquisition of evidence on the Internet",
        url: "https://app.webforensics.kopjra.com",
        recurrence: "Duration",
        planLabel: "Package",
        description: "This solution forensically acquires web pages to be presented in court as evidence, following the international best practice of digital forensics, the standard ISO/IEC 27037:2012.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "For <b>%{count}</b> credits pay a fixed amount of: <b>%{fixed}</b> and over-quota at: <b>%{price}/each</b> - valid for <b>1 %{duration}</b>",
            item_1: "For <b>%{count}</b> credit pay a fixed amount of: <b>%{fixed}</b> and over-quota at: <b>%{price}/each</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "For %{count} credits / %{duration}",
                item_1: "For %{count} credit / %{duration}",
            },
            durationDetail: "The credits will last one <b>%{duration}</b> from the purchase.<br/> Each credit represents 5 minutes of forensic browsing.",
        },
        package: {
            item: "<b>%{count}</b> credits for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> credit for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} credits / %{duration}",
                item_1: "%{count} credit / %{duration}",
            },
            durationDetail: "The credits will last one <b>%{duration}</b> from the purchase.<br/>One credit is equivalent to <b>5 minutes</b> of forensic browsing.<br/>It is possible to use <b>18 credits</b>, as a maximum, in a single session; hence the whole acquisition can last a maximum of <b>90 minutes</b>.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each credit</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the credits consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
    },
    "web-trust": {
        title: "Web Trust",
        subtitle: "Electronic signature of Internet interactions",
        url: "https://app.webtrust.kopjra.com",
        recurrence: "Recurrence",
        description: "Web Trust is a suite of proprietary and patented API solutions for the electronic signature of interactions that take place on web pages, such as the acceptance of privacy policies and the signing of contracts, even in PDF format.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
    },
    "web-sign": {
        title: "Web Sign",
        subtitle: "Electronic signature of documents",
        url: "https://app.websign.kopjra.com",
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "Patented cloud platform for managing complex signature flows starting from documents and templates in PDF format, the first ever to follow the principles of computer forensics.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over quota priced at: <b>%{price}/each</b> - Valid for <b>1 %{duration}</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over quota priced at: <b>%{price}/each</b> - Valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Package of %{count} signatures, with a subscription duration of %{duration}",
                item_1: "For %{count} signature, with a subscription duration of %{duration}",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal.",
        },
        package: {
            freeTrial: {
                month: "Free pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                month_1: "Free pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "Free trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures / %{duration}",
                item_1: "%{count} signature / %{duration}",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: "Dedicated domain: <b>%{price}/%{duration}</b>",
            activation: "First activation cost: <b>%{price} once</b>",
            shorts: {
                domain: "Dedicated domain",
                activation: "First activation cost",
            }
        },
    },
    "timestamp-api": {
        title: "Timestamp API",
        subtitle: "Timestamp",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/timestamp`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for the timestamping of documents, qualified and trustless, i.e. via the Bitcoin blockchain.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> timestamps at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> timestamp at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} timestamps",
                item_1: "Package of %{count} timestamp",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> timestamps each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> timestamp each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> timestamps for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> timestamp for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} timestamps",
                item_1: "%{count} timestamp",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each timestamp</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the timestamp consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                "setup-fee": `Setup fee and support to integration`,
            }
        },

    },
    "document-api-simple": {
        title: "Document Simple API",
        subtitle: "Electronic signature of documents",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for compiling and electronically signing contracts in PDF format.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} signatures",
                item_1: "Package of  %{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            personalization: `Signature environment personalization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                "setup-fee": `Setup fee and support to integration`,
                personalization: `Signature environment personalization`,
            }
        },
    },
    "document-api-simple-otp": {
        title: "Document Simple OTP API",
        subtitle: "Electronic signature of documents with OTP",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for compiling and electronically signing contracts in PDF format, with OTP via SMS.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} signatures",
                item_1: "Package of  %{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            personalization: `Signature environment personalization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                "setup-fee": `Setup fee and support to integration`,
                personalization: `Signature environment personalization`,
            }
        },
    },
    "document-api": {
        title: "Document Simple Forensic API",
        subtitle: "Electronic signature of documents on a forensic layer",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for compiling and electronically signing contracts in PDF format, with OTP via SMS, on a dedicated forensic environment.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} signatures",
                item_1: "Package of  %{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            personalization: `Signature environment personalization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                "setup-fee": `Setup fee and support to integration`,
                personalization: `Signature environment personalization`,
            }
        },
    },
    "document-api-advanced": {
        title: "Document Advanced API",
        subtitle: "Advanced electronic signature of documents",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/aes`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for the compilation and advanced electronic signature of information and contracts in PDF format, including identification of the signer via SPID (Public Digital Identity System).",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "For <b>%{count}</b> signatures pay a fixed amount of: <b>%{fixed}/%{duration}</b> and over-quota at: <b>%{price}/cu</b> and <b>%{linked_price}</b> for each GB send to conservation - valid for <b>1 %{duration}</b>",
            item_1: "For <b>%{count}</b> signature pay a fixed amount of: <b>%{fixed}/%{duration}</b> and over-quota at: <b>%{price}/cu</b> and <b>%{linked_price}</b> for each GB send to conservation - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Per %{count} signatures",
                item_1: "Per %{count} signature",
            },
            durationDetail: "One <b>%{duration}</b> from purchase. Data will be preserved for 10 years at an extra of <b>%{linked_price}</b> per document starting from the second for each signature.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>. Each signature must include maximum <b>1</b> document."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "One <b>%{duration}</b> from purchase. Data will be preserved for 10 years at an extra of <b>%{linked_price}</b> per document starting from the second for each signature.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal. Data will be preserved for 10 years at an extra of <b>%{linked_price}</b> per document starting from the second for each signature.",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            personalization: `Signature environment personalization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                personalization: `Signature environment personalization`,
                "setup-fee": `Setup fee and support to integration`,
            }
        },
    },
    "data-api": {
        title: "Data Forensic API",
        subtitle: "Electronic signature of data streams",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/data`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for electronically signing data streams from IoT applications and devices.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} signatures",
                item_1: "Package of  %{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                "setup-fee": `Setup fee and support to integration`,
            }
        }
    },
    "webpage-api": {
        title: "Webpage Forensic API",
        subtitle: "Electronic signing of web pages",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/hrml`,
        recurrence: "Recurrence",
        planLabel: "Amount",
        description: "API solution for the click-to-sign electronic signature of contracts published on web pages.",
        exclusiveWarn: "Buying another configuration of this product will cancel the active subscription and will start a new one with the selected configuration.",
        exclusiveError: "The active configuration of this product is not modifiable, please wait for natural ending of the subscription or contact support.",
        level: {
            item: "Package of <b>%{count}</b> signatures at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            item_1: "Package of <b>%{count}</b> signature at a price of: <b>%{fixed}/%{duration}</b> with an over-quota priced at: <b>%{price}/each</b>",
            shorts: {
                item: "Package of %{count} signatures",
                item_1: "Package of  %{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        package: {
            freeTrial: {
                year: "<b>Free</b> pack of <b>%{count}</b> signatures each <b>%{duration}</b>",
                year_1: "<b>Free</b> pack of <b>%{count}</b> signature each <b>%{duration}</b>",
                conditions: "<b>Free</b> trial period that gives a limited number of signatures and is renewed every <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> signatures for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> signature for a total of: <b>%{price}</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "%{count} signatures",
                item_1: "%{count} signature",
            },
            durationDetail: "The subscription is valid for a <b>%{duration}</b> from the purchase, with implicit renewal. <br/>The included standard storage lasts for one <b>%{duration}</b> from purchase.",
        },
        consumption: {
            item: "Usage-based at a price of <b>%{price}/each signature</b> - valid for <b>1 %{duration}</b>",
            shorts: {
                item: "Usage-based at %{price}/each (%{duration})",
            },
            durationDetail: "An invoice will be sent with the signatures consumed at the end of the <b>%{duration}</b>, with implicit renewal",
        },
        addons: {
            domain: `Signature environment domain customization at <b>%{price}</b> each <b>%{duration}</b>`,
            "setup-fee": `Setup fee and support to integration <b>%{price}</b>`,
            shorts: {
                domain: `Signature environment domain customization`,
                "setup-fee": `Setup fee and support to integration`,
            }
        },
    },
    "analysis": {
        title: "Analysis",
        subtitle: "",
        description: ""
    },
    "detection": {
        title: "Detection",
        subtitle: "",
        description: "",
    },
    "takedown": {
        title: "Takedown",
        subtitle: "",
        description: "",
    },
    productDetail: {
        selectPlanLabel: "Select Plan",
        addon: "Addon",
        level: "Amount",
        package: "Package",
        duration: "Conditions",
        recurrence: "Recurrence",
        addToCart: "Buy",
        updateCart: "Update order",
        total: "Total",
        noiva: "(VAT excluded)",
        iva: "(VAT included)",
        selectPlan: "Select plan",
        selectPlanError: "You select at least one plan",
    },
    cart: {
        title: "Order Summary",
        subtotal: "Net price",
        iva: "VAT (%{count}%)",
        buy: "Continue buying",
        proceed: "Proceed to order",
        edit: "Edit",
        delete: "Remove",
        deleteConfirm: "Are you sure you want to remove this element from the order?",
        couponTitle: "Apply a discount coupon",
        couponPlaceholder: "Coupon",
        couponButton: "Apply",
        couponError: "Coupon couldn't be applied",
        couponPercent: "Discount of %{count}% has been applied",
        couponAmount: "Discount of %{count} € has been applied",
        tos: "I accept the <a href='https://www.iubenda.com/termini-e-condizioni/18692996' target='_blank' rel='noopener noreferrer'>terms of service</a> and I have read the Kopjra's <a href='https://www.iubenda.com/privacy-policy/18692996' target='_blank' rel='noreferrer noopener'>privacy policy</a>.",
        secure: "Safe and guaranteed payments",
        financed: "Project co-financed by POR FESR Emilia-Romagna 2014-2020",
    },
    payment: {
        access: "Access",
        billing: "Billing",
        payment: "Payment",
        outcome: "Confirmation",
        summary: "Cart summary",
        registerLoginTitle: "To continue please register or login",
        registerLoginButton: "Register / Login",
        cardData: "Card data",
        newCard: "New credit card",
        paymentMethod: "Payment Methods",
        deleteConfirmation: "Are you sure you want to delete the selected payment method?",
        setDefault: "Set as default",
        delete: "Remove",
        deleteError: "Error deleting payment method, maybe it is your only one, if so, it can not be deleted",
        saved: "Saved payment methods",
        iban: "IBAN",
        sepa: "SEPA",
        expired: "Expired %{month}/%{year}",
        pay: "Pay",
        setError: "Error setting the payment method, please retry",
        stripeError: "The payment platform is not ready yet, please retry",
        cardError: "The card specified is not valid",
        sepaMissingDataError: "The account holder name and email must be provided",
        sepaError: "Error creating the SEPA payment method",
        defaultError: "The default payment method is not valid, please change it",
        sepaName: "Account holder's name",
        sepaEmail: "Email",
        emptyCart: "No order available",
        gotoStore: "To start the purchases go to the ",
        confirmEmail: "Please verify your email address by clicking the link we sent to the email <b>%{email, afterwards click the button bellow to proceed.",
        verified: "Email Verified",
        resendEmail: "Can't find the verification email? Resend it",
        confirmation: {
            titleOK: "Confirmed Order",
            subtitleOK: "Thanks for ordering with the Kopjra Store. <br/><br/> A confirmation email will arrive to <b>%{email}</b>. <br/>To start using the purchased services follow the instructions in the email.",
            titlePartial: "Partial confirmed order",
            subtitlePartial: "Thanks for ordering with the Kopjra Store. <br/><br/> Your order has been partially confirmed, see bellow. <br/>For the part that has been confirmed an email will arrive to <b>%{email}</b>. <br/>To start using the purchased services follow the instructions in the email. <br/><br/>The non confirmed purchases will remain in the <a href='/cart' class='link'>cart</a>, please retry",
            titleNone: "Order could not be confirmed",
            subtitleNone: "Your order has not been confirmed, please retry or contact assistance",
            confirmedTitle: "Confirmed cart elements",
            notConfirmedTitle: "Failed cart elements",
            confirmed: "Confirmed",
            notConfirmed: "Not confirmed",
            paymentError: "There were payment errors, maybe choose another payment method.",
        },
    },
    billingInfo: {
        name: "Company name",
        vat: "VAT number",
        street: "Street",
        number: "Number",
        city: "City",
        state: "State",
        country: "Country",
        zip: "Zip Code",
        email: "Email",
        invoiceEmail: "Email for invoices",
        confirmEmail: "Confirm email",
        emailError: "Required valid email and must be equal to CONFIRM EMAIL",
        vatError: "Must be a valid VAT number",
        required: "Required",
        proceed: "Proceed",
        saveError: "There was an error saving the billing information, retry later",
    },
    generic: {
        yes: "Yes",
        no: "No",
        from: "From",
        confBuy: "Configure and Buy",
        configure: "Configure",
        select: "Select",
        remove: "Remove",
        access: "Go to ",
        solutions: "Solutions",
        freePlan: "Try for free",
        modality: {
            year: "Year",
            month: "Month"
        },
        currency: {
            eur: "€"
        },
        recurrence: {
            day: "Daily",
            year: "Annually",
            month: "Monthly",
            week: "Weekly",
        },
        duration: {
            day: "day",
            annual: "year",
            year: "year",
            month: "month",
            quarter: "quarter",
            semiannual: "semester",
        },
        download: "Download",
    },
    error: {
        page: "Error",
        forbidden: "Access Denied",
        notFound: "Page not found",
        notAuthenticated: "Not authenticated",
        getProducts: "Error getting products, reload the page",
        malformedCart: "The cart is malformed",
    },
    kptable: {
        filters: "Filters",
        apply: "Apply",
        show_columns: "Show columns",
        pager: {
            first: "First",
            last: "Last",
        },
        actions: "Actions",
        date: {
            short: "MM/DD/YYYY",
            long: "MM/DD/YYYY hh:mm a",
        },
        confirm: {
            yes: "Yes",
            no: "No",
        },
        show: "Show",
        globalSelectionWarn: "This selection only applies for the %{count} visible elements",
    },
    footer: {
        privacy: "Privacy",
        terms: "Terms",
        cookie: "Cookie",
        preferences: "Preferences",
    },
    globalAlert: "",
    // globalAlert: "Extraordinary maintenance: <b>Thursday, August 31st 2023 from 21:00 until 23:00 (CEST)</b> it may not be possible to make purchases and edit the profile",
};

export default en;
